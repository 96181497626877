import Pikaday from "pikaday";

document.addEventListener('turbo:load', () => {


  
  var pikadayElements = document.querySelectorAll('.pikaday');
  
  if (pikadayElements.length > 0){
    for(i = 0; i < pikadayElements.length; i++){
      new Pikaday({
        field: pikadayElements[i]
      });
    }
  }
})