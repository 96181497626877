import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="css-switcher"
export default class extends Controller {
  
  static targets = ['button']
  
  connect() {
    let sheet = document.querySelector('.darkmode_stylesheet')
    
    if(document.cookie == 'holiday_dark_mode=true'){
      sheet.rel = 'stylesheet';
    }

    this.buttonTarget.addEventListener('click', () => {
      if(sheet.rel == ""){
        sheet.rel = 'stylesheet';
        document.cookie = "holiday_dark_mode=true"
      } else{
        sheet.rel = "";
        document.cookie = 'holiday_dark_mode=false';
      }
    })

  }
}
